.container {
  padding: 15px;
  min-width: 300px
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeModalButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 0px;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.closeModalButton:hover {
  background-color: rgb(236, 232, 232);
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.h2 {
  margin: 0;
  padding: 0;
}

.select {
  padding: 12px;
  font-size: medium;
  width: 100%;
}

.input {
  padding: 5px;
  font-size: medium;
  line-height: 30px;
  width: 100%;
}

.input[type=submit] {
  background-color: #12574d;
  color: white;
  border: 0px;
  cursor: pointer;
}

.input[type=submit]:disabled {
  background-color: rgb(167, 158, 158);
  color: rgb(59, 55, 55);
}

.outsideAddButton {
  background-color: #12574d;
  color: white;
  padding: 7px 15px;
  border: 0px;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 8px;
}