.boxWarning {
  padding: 1rem;
  margin: .5rem;
  background-color: #E7EEED;
  border-radius: 5px;
}

.container {
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
}

.buttonsContainer {
  display: flex;
  gap: .6rem;
}


.buttons {
  border: 1px solid #12574d;
  border-radius: 5px;
  padding: .5rem 1rem;
  width: max-content;
  font-weight: bold;
}


.buttons:hover {
  cursor: pointer;
  box-shadow: 0 0 7px 5px #12574d;
}

.primaryButton {
  background-color: #12574d;
  color: #E7EEED;
}

.secondaryButton {
  color: #12574d;
  background-color: transparent;
}

@media only screen and (max-width: 900px) {
  .container {
    width: 90%;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    width: 100%;
  }

  .buttonsContainer {
    flex-direction: column;
  }

  .buttons {
    width: auto
  }
}

@media only screen and (max-width: 400px) {
  .container {
    flex-direction: column;
    text-align: center;
  }
}
