.image {
    display: block;
    height: 400px;
    width: 400px;
    z-index: 1;
    text-align: center;
  }

.canvas {
  height: 400px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.7;
  width: 400px;
  z-index: 2;
}

.canvas-overlay {
  position: absolute;
  left: 0;
  top: 0; 
  height: 100%;
  width: 100%;
  pointer-events: none;
  display: flex;
  flex-direction: column;
}

.canvas-overlay .canvas-image {
  pointer-events: all;
} 

.move-buttons {
  width: 72px;
  height: 72px;
  margin-top: 12px;
  margin-left: 16px;
}

.zoom-buttons {
  width: 72px;
  height: 72px;
  margin-top: 12px;
  margin-right: 16px;
}

.cover-bottom {
  height: 230px;
}

.slider-container {
  width: 40px;
  position: relative;
  margin-left: 16px;
  margin-bottom: 18px;
  pointer-events: auto;
}

.circle {
  height: 24px;
  width: 24px;
  border: solid 4px #e9e9e9;
  border-radius: 9999px;
  margin-left: 3px;
}

.circle-small {
  height: 9px;
  width: 9px;
  border: solid 3px #e9e9e9;
  border-radius: 9999px;
}