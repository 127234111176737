/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(95, 95, 95) #fafafa;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #fafafa;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,1);
  border-radius: 10px;
  border: 0px none #ffffff;
}

body {
  overscroll-behavior: none;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  touch-action: manipulation;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'SohneBuch';
  src: url('./assets/fonts/Sohne/Söhne-Buch.otf');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'RegisterLight';
  src: url('./assets/fonts/Register/Register_-Light.otf');
  font-style: normal;
  font-weight: 100 300;
}

@font-face {
  font-family: 'RegisterLightItalic';
  src: url('./assets/fonts/Register/Register_-LightItalic.otf');
  font-style: normal;
  font-weight: 300;
}
