.oval {
    border: 0;
    background-color: #b6b9ba;
    height: 24px;
    width: 24px;
    display: flex;
    border-radius: 9999px;
    pointer-events: auto;
    padding: 0;
  
    &.is-variant {
      background-color: #000;
      opacity: 0.56;
    }
  }