.overview-page-table-sticky {
  border: 1px solid #e7e7e7;

  thead th:first-child,
  tbody td:first-child {
    background: white;
    position: sticky;
    left: 0;
    z-index: 2;
  }
}
