.Button {
  color: #ffffffad;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 3.5rem;
  border: 0px;
  background-color: #0000008f;
  padding: 1rem;
  cursor: pointer;
  box-shadow: 0px 2px 8px 2px #00000054;
  outline: none;
  position: absolute;
  touch-action: manipulation;
}

.Range {
  writing-mode: bt-lr; /* IE */
  -webkit-appearance: slider-vertical; /* WebKit */
  width: 8px;
  height: 175px;
  padding: 0 5px;
}

.ButtonActive {
  color: #00efae;
}
