@import 'assets/stylesheets/coverImage';
@import 'assets/stylesheets/overviewPage';
@import 'assets/stylesheets/oval';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: auto !important;
}

.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1rem;
  width: auto;
}

.my-masonry-grid_column {
  background-clip: padding-box;
}

.slick-prev {
  left: -2px;
  z-index: 9;
  height: auto;
  width: auto;
}

.slick-next {
  right: 5px;
  z-index: 9;
  height: auto;
  width: auto;
}

.slick-prev::before,
.slick-next::before {
  font-family: 'slick';
  font-size: 28px;
  line-height: 1;
  opacity: 0.85;
  color: #12574d;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-slider {
  padding: 0 2rem;
}

.slick-slide:focus,
.slick-slide:visited {
  outline: none !important;
}

.slick-slide div:focus,
.slick-slide div:visited,
.slick-slide div {
  outline: none !important;
}

.no-padding-slider {
  padding: 0;
}

.margin-top-date {
  margin-top: 8px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiAccordionSummary-content,
.MuiAccordionSummary-content.Mui-expanded {
  margin: 8px 0 !important;
  display: inline-block !important;
}

.cursors {
  cursor: pointer;
}

.container {
  display: inline-block;
  vertical-align: top;
  padding: 1rem;
  text-align: center;
}

.element-column {
  width: 30%;
  margin: 0.5rem;
  display: inline-block;
  height: auto;
  text-align: left;
  vertical-align: top;
}

.MuiAccordion-root::before {
  display: none;
}

.class-padding {
  padding: 1rem !important;
}

.react-tel-input .special-label {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-family: Messina Sans SemiBold;
  font-weight: 400;
  line-height: 1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

// CKEditor
.ck-file-dialog-button {
  display: none;
}

.ck-editor__editable_inline {
  height: 500px;
  min-height: 500px;
  max-height: 80%;
}

.ck-editor {
  font-family: Arial, Helvetica, sans-serif !important;
}

// MUI Tabs scroll horizontal fix
.MuiTabs-scroller {
  overflow-x: auto !important;
}